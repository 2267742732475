.input-field.col {
  width: 25rem;
  border: 1rem;
  border-color: black;
  margin-left: 25hw;
}

.black-text.noinput-url {
  border: solid;
  border-color: black;
  
}