.btn-extended__60a3d156 {
  display: block;
  width: 100%;
}

#toast-container {
  top: auto !important;
  right: auto !important;
  bottom: 10%;
  left:7%;  
}