.overlay_container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 82, 201, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0052c9; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
