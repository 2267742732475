.login__58c5c660 {
    margin-top: 0rem;
    margin-bottom: 0rem;
    background-color: #5938BA;
}

.btn-extended__ae140c51{
    display: block;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    color: white;
}
