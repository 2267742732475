$logoheight: 131px;
$logowidth: 118px;
$menuheader-marginleft: 0%;
$menuheader-fontsize:25px;
$logo-float: left;
.main-header{
  background-size: cover;
  background-position: center;
  background-color: #ffff;
  color: #6335bf;
  .transparent {
    height: 0;
    .App-logo {
      height: $logoheight;
      width: $logowidth;
      // padding: 0;
      // margin-left: 0.5vw;
      // margin-top: 21px;
      // outline-style: solid;
      // outline-color: white;
      // outline-width: 30px;
    }
    .brand-logo.right{
      @media screen and (max-width: 994px) {
        left: 10px;
      }
    }
    .hide-on-med-and-down{
      margin-left: $menuheader-marginleft;
      margin-top: 0%;
      background-color: #5938ba;
      width: 100%;
      padding-left: 10.5%;
      li {
        padding-top: 2%;
        padding-left: 2rem;
        height: 9rem;
        @media screen and (max-width: 1482px) {
          padding-left: 0.2rem;
        }

      }
      .option {
        font-size: $menuheader-fontsize;
        color: white;
        width: auto;
      }
      .faq-width{
        width: 16vw;
      }
    }
    .hide-on-med-and-down li:nth-child(6) {
      float: $logo-float;
    }
  }
  .showcase {
    padding-top: 20vh;
    background-size: cover;
    min-width: inherit;
    height: fit-content;
    svg {
      position: relative;
      bottom: -10px;
    }
    .container {
      margin-top: 10vh;
    }
    .row .col {

      .buttons{
        display: flex;
        justify-content: space-around;
      }

      h1 {
        color: #5938BA;
      }

    }
  }
}


.pather {
    position: absolute;
    z-index: 3;
    background-color: transparent;
    background-image: url(../../assets/pathbottom.png);
    background-size: 100% 84%;
    height: 12rem;
    left: 0px;
    object-fit: cover;
    opacity: 1;
    top: 675px;
    width: 100%;
}

.admin-button {
  position: absolute;
  display:block;
}

