#envelope{
  position: relative;
  width: 600px;
  height: 400px;
  margin: 400px auto 0 auto;
}

#envelope_front{
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  height: 400px;
  z-index: 1; /* This seems required for Chrome */
  background: #FFF url('../../assets/card-sprite.png') 0px 0px;
  cursor: pointer;
  -webkit-transition: all 4s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg) translateZ(10px);
}

#envelope_front.flipped{
  -webkit-transform: rotateY(-180deg);
}

#envelope_back{
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  height: 400px;
  background: #FFF url('../../assets/card-sprite.png') -600px -400px;
  -webkit-transition: all 4s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(180deg) translateZ(3px);
}

#envelope_back.flipped{
  -webkit-transform: rotateY(0deg);
}

#flap_outside{
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  height: 200px;
  background: transparent url('../../assets/card-sprite.png') -600px -200px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -webkit-transform: rotateX(0) translateZ(3px);
}

#flap_outside.open{
  -webkit-transform: rotateX(180deg) translateZ(0);
}

#flap_inside{
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  height: 200px;
  background: transparent url('../../assets/card-sprite.png') -600px 0px;
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -webkit-transform: rotateX(-180deg) translateY(-200px) translateZ(3px);
}

#flap_inside.open{
  -webkit-transform: rotateX(0deg) translateY(-200px) translateZ(0);
}

#envelope_back_outside{
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  height: 400px;
  cursor: pointer;
  background: transparent url('../../assets/card-sprite.png') 0px -400px;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translateZ(2px);
}

#card{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 580px;
  height: 380px;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translateZ(1px);
}

#card.removed{
  -webkit-animation-name: remove-card;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  -webkit-animation-delay: 0;
  -webkit-animation-play-state: running;
  -webkit-animation-fill-mode: forwards;
}

#card_outside_front{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #FFF url('../../assets/card-sprite.png') -1800px 0px;
  cursor: pointer;
  -webkit-transition: all 1s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -webkit-transform: rotateX(0deg) translateZ(0px);
}

#card_outside_front.open{
  -webkit-transform: rotateX(180deg);
}

#card_inside_top{
  width: 100%;
  height: 100%;
  position: absolute;
  background: #FFF url('../../assets/card-sprite.png') -1210px -10px;
  -webkit-transition: all 1s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0 380px;
  /* 379 is used instead of 380 to prevent any gap between the two layers (visible in Safari) */
  -webkit-transform: translateZ(0px) translateY(-379px) rotateX(-180deg);
}

#card_inside_top.open{
  -webkit-transform: translateZ(0px) translateY(-379px) rotateX(0deg);
}

#card_inside_bottom{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1; /* Hide behind #card_outside_front and #card_inside_top */
  background: #FFF url('../../assets/card-sprite.png') -1210px 390px;
}

/* Animation Keyframes for removing the card */
@keyframes remove-card {

  0% {
    -webkit-transform: translateY(0px) translateZ(1px);
  }

  33% {
    -webkit-transform: translateY(-400px) translateZ(1px);
  }

  67% {
    -webkit-transform: translateY(-400px) translateZ(3px);
  }

  100% {
    -webkit-transform: translateY(0px) translateZ(3px);
  }

}


.ecard_selected {
  position:relative; width: 100%; height:100%;
}