.contact {
  padding: 10px 0 10px 0;
  color:#6335bf;
  background-size: cover;
  background-color: #ffffff;
  min-width: inherit;
  height: 115%;
  h1 {
    
    text-align: center;
  }
  .row .col {

    .buttons{
      display: flex;
      justify-content: space-around;
    }

  }
}