$logoheight: 72px;
$logowidth: 243px;
$menuheader-marginleft: 25%;
$menuheader-fontsize:30px;
$logo-float: right;

.main-header__17aaa2a7 {
  background-size: cover;
  background-position: center;
  background-color: #6746d0;
  height: 5rem;
  min-width: 100%;
  color: #fff;
  .transparent {
    .App-logo {
      height: $logoheight;
      width: $logowidth;
    }
    .hide-on-med-and-down{
      margin-left: $menuheader-marginleft;
      .option {
        font-size: $menuheader-fontsize;
      }
    }
    .hide-on-med-and-down li:nth-child(6) {
      float: $logo-float;
    }
  }
  .showcase {
    padding-top: 100px;
    color:#d2f1e4;
    background-size: cover;
    background: linear-gradient(180deg, #6746d1 0%, #ffc469 101.27%);    
    min-width: inherit;
    height: 115%;
    .row .col {

      .buttons{
        display: flex;
        justify-content: space-around;
      }

    }
  }
}

.main-header__3df3618b{  
  background-color: #6746d0;
  background-size: cover;
  background-position: top;
  height: 13rem;
  color: #fff;
  .transparent {
    .App-logo {
      height: $logoheight;
      width: $logowidth;
    }
    .brand-logo.right{
      @media screen and (max-width: 994px) {
        left: 10px;
      }
    }
    .hide-on-med-and-down{
      margin-left: $menuheader-marginleft;
      .option {
        font-size: $menuheader-fontsize;
      }
      .faq-width{
        width: 30vw;
      }
    }
    .hide-on-med-and-down li:nth-child(6) {
      float: $logo-float;
    }
  }

  .showcase {
    background: linear-gradient(180deg, #6746d1 0%, #ffc469 101.27%);
    min-width: 100%;
  }
}

.pather {  
    position: absolute;
    z-index: 3;
    background-color: transparent;
    background-image: url(../../assets/pathbottom.png);
    background-size: 100% 84%;
    height: 12rem;
    left: 0px;
    object-fit: cover;
    opacity: 1;    
    top: 675px;
    width: 100%;  
}

.admin-button {
  position: absolute;
  display:block;
}